<template>
<div>
    <div class="dropdown">
    <button class="btn btn-secondary btn-sm dropdown-toggle" type="button" id="packageGroupListActions" ref="packageGroupListActions" data-bs-toggle="dropdown" aria-expanded="false">
        İşlemler
    </button>
    <ul class="dropdown-menu" aria-labelledby="packageGroupListActions">
        <li><a class="dropdown-item" :href="'packages-by-group/'+item.packageGroupId">Paketler</a></li>
        <li>
            <hr class="dropdown-divider">
        </li>
        <li><a class="dropdown-item" @click.prevent="edit(item)">Düzenle</a></li>
        <li><a class="dropdown-item" @click.prevent="remove(item)" href="#">Sil</a></li>
        </ul>
    </div>

      
    
       

</div>
</template>

<script>
export default {
    name:'PackageGroupListActions',
    props:['item','packagekey'],

    methods:{
        remove(item){
            this.$emit('remove',item);
        },
        edit(item){
            this.$emit('edit',item);
        }
    },
    
   
}
</script>