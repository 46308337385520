<template>
  <div class="card mb-5 mb-xl-12">
    <div class="card-header border-0 cursor-pointer" role="button" data-bs-toggle="collapse" data-bs-target="#kt_account_profile_details" aria-expanded="true" aria-controls="kt_account_profile_details">
      
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Paket Grupları</h3>

        
      </div>      
    </div>
    
    
    <div id="kt_account_profile_details" class="collapse show" v-if="packages">

        <div id="package-detail" v-if="showDetail">
            <div class="card-body border-top p-9">
                
                <PackagDetailForm :selectedPackage="selectedPackage" :mode="mode" v-if="selectedPackage" :packageGroupId="packageGroupId" />
            </div>

            <!--begin::Actions-->
            <div class="card-footer d-flex justify-content-end py-6 px-9">
                <button type="reset" @click.prevent="cancelForm()" class="btn btn-white btn-active-light-primary me-2">Vazgeç</button>

                <button type="submit" id="kt_account_profile_details_submit" ref="submitButton1" @click.prevent="updatePackageGroup()" class="btn btn-primary">
                    <span class="indicator-label"> Kaydet </span>
                    <span class="indicator-progress">
                    Lütfen bekleyin...
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                    </span>
                </button>
            </div>
            <!--end::Actions-->
        </div>


        <div class="card-body border-top p-9" v-show="!showDetail">
            <div class="table-responsive">
                <a href="" @click.prevent="addPackageGroup()" class="btn btn-primary float-right">Yeni Paket Ekle</a>
              <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                <thead>
                    <tr class="fw-bolder">
                    <th>Sıra</th>
                    <th>Paket Grubu</th>
                    <th>Açıklama</th>
                    <th>Durum</th>                    
                    <th>Planlı Takip</th>
                    <th>İşlem</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, i) in packages" :key="i">
                    <td>{{item.orderNo}}</td>
                    <td>{{item.groupName}}</td>
                    <td>{{item.description}}</td>
                    <td><span :class="'badge badge-light-'+statusses.class[item.status]">{{statusses.label[item.status]}}</span></td>                    
                    <td><span :class="'badge badge-light-'+planned.class[item.plannedFlag ? 1 : 0]">{{planned.label[item.plannedFlag ? 1 : 0]}}</span></td>                    
                    
                    <td><ActionsMenu :item="item" :packagekey="i" @edit="editPackageGroup($event)" @remove="removePackageGroup($event)" /></td>
                    </tr>            
                </tbody>
              </table>
      
            </div>
        </div>
    </div>
    
        
    
  </div>


</template>



<script>
import api from '@/core/services/ApiService'
import ActionsMenu from "@/components/dropdown/PackageGroupListActions.vue";
import PackageActionToolbar from "@/components/actions/PackageToolbar.vue";
import PackagDetailForm from '@/components/modals/forms/EditPackageGroup.vue';
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";

const newPackageGroupValues={
    groupName:null,
    status:null,
    plannedFlag:null,
    description:null,
    orderNo:null,
}

export default  {
  name:'PackageGroups',
  components:{
      ActionsMenu,
      PackagDetailForm,      
  },
  data(){
    
    return{        
        selectedPackage:newPackageGroupValues,
        mode:'new',

      showDetail:false,
      
      statusses : {
        class:{
          'AC':'success',
          'DA':'danger'
        },
        label:{
          'AC':'Aktif',
          'DA':'Pasif'

        }
      },
       planned : {
        class:{
          1:'success',
          0:'danger'
        },
        label:{
          1:'Evet',
          0:'Hayır'

        }
      },
      packages:null,
      

    }
  },

  

  methods:{
      editPackageGroup(item){
          this.showDetail=true;
          this.mode='edit';
          this.selectedPackage = item;
      },

      cancelForm(){
          this.showDetail=false;
      },

      addPackageGroup(){
          this.mode='new';
          this.selectedPackage = newPackageGroupValues;        
          this.showDetail = true;

      },

      removePackageGroup(item){
        
        Swal.fire({
          icon:'warning',
          title:'Paket grubunu silmek üzeresiniz',
          text:item.groupName +' paket grubunu silmek istediğinize emin misiniz?\nBu işlemin geri dönüşü yoktur!',
          showCancelButton:true
        }).then((result)=>{
          if(result.isConfirmed){
            api.get('/Package/DeletePackageGroup/'+item.packageGroupId).then((res)=>{
              Swal.fire({
                title:'Paket Grubu Silme',
                icon:'success',
                text:'Paket grubu silindi',
              }).then(()=>{
                window.location.reload();
              })
            });
          }else{
            
          }
        });
      },

      updatePackageGroup(){
          let apiAddress = this.mode=='new' ? '/Package/NewPackageGroup' : '/Package/UpdatePackageGroup';
          this.selectedPackage.plannedFlag = this.selectedPackage.plannedFlag == null ? false : this.selectedPackage.plannedFlag;
          this.selectedPackage.orderNo = parseInt(this.selectedPackage.orderNo);

          api.post(apiAddress,this.selectedPackage).then((res)=>{
              Swal.fire({
                  text:'Paket grubu bilgileri güncellendi',
                  icon:'success',
              }).then(()=>{
                  window.location.reload();
              });
          }).catch((err)=>{
              console.log(err);
          })
      }
  },


  created(){
      let aComponent = {component:PackageActionToolbar, props:{},events:['addPackage']};
     setCurrentPageBreadcrumbs("Paket Grupları Listesi", ["Paketker"]);
     api.get('/Package/ListPackageGroup/JJ?pageSize=10&page=1').then((res)=>{
         this.packages = res.data;
     });
   
  },

}

</script>
<style scoped>
input:disabled{cursor: not-allowed; background-color: #eee;}
</style>