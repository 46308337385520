<template>
    <div>
        <div class="row">
            <div class="col-lg-9">
                <div class="row mb-10">
                    <div class="row">
                        <div class="col-lg-12 fv-row">
                            <label class="col-form-label">Grup Adı</label>                            
                            <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.groupName" />
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-4 fv-row">
                        <label class="col-form-label">Durum</label> 
                            <div class="d-flex align-items-center mt-3">
                            <label class="form-check form-check-inline form-check-solid me-5">
                                <input class="form-check-input" value="AC" name="status" :checked="selectedPackage.status == 'AC'" type="radio" v-model="selectedPackage.status" />
                                <span class="fw-bold ps-2 fs-6"> Aktif </span>
                            </label>

                            <label class="form-check form-check-inline form-check-solid">
                                <input class="form-check-input"  value="DA" name="status" :checked="selectedPackage.status == 'DA'" type="radio" v-model="selectedPackage.status" />
                                <span class="fw-bold ps-2 fs-6"> Pasif </span>
                            </label>
                        </div>
                    </div>

                    <div class="col-lg-4 fv-row">
                        <label class="col-form-label">Planlı Takip</label> 
                                                
                        <div class="d-flex align-items-center mt-3">
                            <label class="form-check form-check-inline form-check-solid me-5">
                                <input disabled class="form-check-input" :checked="selectedPackage.plannedFlag" type="radio" v-model="selectedPackage.plannedFlag" />
                                <span class="fw-bold ps-2 fs-6"> Evet </span>
                            </label>

                            <label class="form-check form-check-inline form-check-solid">
                                <input disabled class="form-check-input" type="radio" :checked="!selectedPackage.plannedFlag" v-model="selectedPackage.plannedFlag" />
                                <span class="fw-bold ps-2 fs-6"> Hayır </span>
                            </label>
                        </div>
                    </div>

                    <div class="col-lg-2 fv-row">
                        <label class="col-form-label">Sıra</label>                                         
                        <input type="text" name="name" class="form-control form-control-lg form-control-solid mb-3 mb-lg-0" v-model="selectedPackage.orderNo" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12 fv-row">
                        <label class="col-form-label">Açıklama</label> 
                        <textarea class="form-control" v-model="selectedPackage.description" cols="30" rows="5"></textarea>
                    </div>
                </div>
                
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name:'EditPackage',
    props:['selectedPackage','mode','packageGroupId'],
   
        
}
</script>